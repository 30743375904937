<template>
  <section>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <!-- end appbar nav icon -->
      <v-toolbar-title class="headline font-weight-semibold"
        >Impact Projects ({{ listMeta.total }})</v-toolbar-title
      >

      <!-- end toolbar title -->
      <v-spacer />
      <!-- end -->
      <v-row v-show="showSearch" align="center" style="max-width: 370px">
        <v-text-field
          class="search"
          ref="searchField"
          flat
          hide-details
          solo
          rounded
          clearable
          background-color="grey lighten-3"
          label="Search charity name"
          v-model="filter.search"
          @input="search"
        ></v-text-field>
      </v-row>

      <v-btn class="ml-5" icon @click="showSearchField">
        <v-progress-circular
          indeterminate
          v-if="isLoading"
        ></v-progress-circular>

        <img src="@/assets/icons/search.svg" alt height="17.7px" v-else />
      </v-btn>
    </v-app-bar>
    <!-- end app bar -->

    <div
      id="charity-list"
      v-infinite-scroll="loadMore"
      :infinite-scroll-disabled="isLoading"
      infinite-scroll-distance="100"
    >
      <v-data-table
        :headers="headers"
        :items="charities"
        hide-default-footer
        disable-pagination
      >
        <template v-slot:body="{ items: charities }">
          <tr v-for="charity in charities" :key="charity.id" height="75px">
            <td>{{ charity.id }}</td>
            <td>{{ charity.name }}</td>
            <td>{{ charity.description }}</td>
            <td>
              <a :href="charity.website" target="_blank">{{
                charity.website
              }}</a>
            </td>
          </tr>
        </template>
      </v-data-table>
    </div>
  </section>
  <!-- end section -->
</template>

<script>
import ControlsMixin from '@/utils/mixins/Controls'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import infiniteScroll from 'vue-infinite-scroll'
import { mapActions, mapMutations, mapState } from 'vuex'
import debounce from 'lodash/debounce'

export default {
  name: 'UsersPage',

  mixins: [ControlsMixin],

  directives: {
    infiniteScroll,
  },

  components: {
    AppBarNavIcon,
  },

  data() {
    return {
      showSearch: false,
      isLoading: false,
      filter: {
        search: '',
      },
      headers: [
        {
          text: 'Id',
          value: 'id',
          width: '30%',
        },
        {
          text: 'Name',
          value: 'name',
          width: '15%',
        },
        {
          text: 'Description',
          value: 'displayName',
          width: '35%',
        },
        {
          text: 'Website',
          value: 'website',
          width: '20%',
        },
      ],
    }
  },

  mounted() {
    this.clearCharities()
    this.fetchCharities()
  },

  computed: {
    ...mapState({
      charities: (state) => state.charity.list,
      listMeta: (state) => state.charity.listMeta,
    }),
  },

  methods: {
    ...mapActions({
      getCharities: 'charity/getCharities',
    }),

    ...mapMutations({
      clearCharities: 'charity/clearCharityList',
    }),

    loadMore() {
      if (this.listMeta.current_page < this.listMeta.last_page) {
        this.fetchCharities(this.listMeta.current_page + 1)
      }
    },

    async fetchCharities(page = 1) {
      if (!this.isLoading) {
        let params = { page, 'type[]': 'nonprofit' }

        if (this.filter.search) {
          params.query = this.filter.search
        }

        this.isLoading = true
        await this.getCharities(params)
        this.isLoading = false
      }
    },

    async showSearchField() {
      this.showSearch = !this.showSearch
      await this.$nextTick()
      this.$refs.searchField.$refs.input.focus()
    },

    search: debounce(function () {
      this.clearCharities()
      this.fetchCharities()
    }, 600),
  },
}
</script>

<style scoped>
#add-user-menu {
  position: fixed;
  bottom: 64px;
  right: 64px;
}
#add-user-button {
  position: fixed;
  bottom: 64px;
  right: 64px;
}

table {
  border-collapse: separate;
  border-spacing: 0 1em;
}
</style>
